<template>
  <div>
    <div class="mt-2" style="background-color: white; padding: 10px">
      <div class="container">
        <div class="col-md-12">
          <h5>Farm Retreats</h5>
          <div>
            <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
              <div class="row">
                
                <div class="col-md-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group
                      id="input-group-1"
                      label="Select Farm:"
                      label-for="input-1"
                    >
                      <b-form-select
                        v-model="details._projectID._id"
                        @change="projectSelected"
                        class="mb-3"
                      >
                        <b-form-select-option value=""
                          >Please select an Project</b-form-select-option
                        >
                        <b-form-select-option
                          :value="project._id"
                          v-for="(project, index) in projects"
                          :key="index"
                          >{{ project.name }}</b-form-select-option
                        >
                      </b-form-select>
                      <span class="error">{{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                  <div v-if="details._projectID._id">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group
                        id="input-group-2"
                        label="Name:"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          type="text"
                          v-model="details._projectID.name"
                          readonly
                        ></b-form-input>
                        <span class="error">{{ errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group
                        id="input-group-3"
                        label="Location:"
                        label-for="input-3"
                      >
                        <b-form-input
                          id="input-3"
                          type="text"
                          v-model="details._projectID.location"
                          readonly
                        ></b-form-input>
                        <span class="error">{{ errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
                  </div>

                  <b-form-checkbox
                    inline

                    name="amenities"
                    v-model="details.amenities"
                    v-for="item in amenities"
                    :key="item._id"
                    :value="item._id"
                    >{{ item.name }}</b-form-checkbox
                  >

                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Description:"
                    label-for="input-2"
                  >
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-textarea
                        id="input-2"
                        v-model="details.description"
                        placeholder="Enter description..."
                        rows="9"
                        max-rows="9"
                      ></b-form-textarea>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Resort Image:"
                    label-for="ProjectResortImage"
                  >
                    <input
                      type="file"
                      ref="ProjectResortImage"
                      @change="uploadImage"
                    />
                    <img
                      class="img-thumbnail"
                      width="300px"
                      alt=""
                      :src="details.image"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <h5>Member Credits</h5>
                </div>

                <div class="col-md-12">
                  <table class="table table-sm">
                    <thead class="small thead-light">
                      <tr>
                        <th>#</th>
                        <th>Land Extends</th>
                        <th>Credits</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(credit, index) in details.credits" :key="index">
                        <td>{{ index + 1 }}</td>

                        <td>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-input
                              size="sm"
                              v-model="credit.acre"
                              placeholder="Enter acre"
                              required
                              readonly
                            >
                            </b-form-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-input
                              class="form-control form-control-sm"
                              id="input-2"
                              v-model="credit.points"
                              placeholder="Enter Credits"
                              required
                            ></b-form-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <b-button size="sm" variant="success" @click="submitForm()">
                Update
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    
  },
  name: "ResortView",
  data() {
    return {
      acres: [0.25, 0.5, 0.75, 1],
      form: {
        project_id: "",
        amenities: [],
        description: "",
        credits: [],
        image: "",
      },
      details: "",
      rooms: [],
      amenities: [],
      projects: [],
      selected_project: false,

    };
  },
  mounted() {
    this.getAmenities();
    this.setLandExtend();
    this.getData();
    this.getProjects();
    this.GetHotelRoomsData();

  },
  methods: {
    setLandExtend() {
      this.acres.forEach((acre) => {
        this.form.credits.push({
          points: "",
          acre: acre,
        });
      });
    },
    uploadImage() {
      this.details.image = this.$refs.ProjectResortImage.files[0];
    },
    getAmenities() {
      axios.get("amenities").then((res) => {
        console.log(res.data);
        this.amenities = res.data.data;
      });
    },
    getProjects() {
      axios.get("project").then((res) => {
        this.projects = res.data.data;
      });
    },
    projectSelected() {
      let filteredProject = this.projects.filter(
        (project) => project._id == this.form.project_id
      );
      this.selected_project = filteredProject[0];
    },
    getData() {
      let resortId = this.$route.params.id;
      axios.get("project-resorts/" + resortId).then((resp) => {
        let data = resp.data.data;
        let amenities = data.amenities;
        this.details = data;
        if (amenities.length > 0) {
          this.details.amenities= []
          amenities.forEach((amenity) => {
            this.details.amenities.push(amenity._amenitiesID._id)
          })
        }
      });
    },
    GetHotelRoomsData() {
      let resortId = this.$route.params.id;
      axios.get("project-resort-rooms/" + resortId).then((resp) => {
        console.log(resp);
        this.rooms = resp.data.data;
        console.log(this.details.rooms);
      });
    },
     submitForm() {
      console.log(this.details);
      let id = this.$route.params.id;
      let formData1 = new FormData();
      formData1.append("project_id",this.details._projectID._id);
      formData1.append("amenities",JSON.stringify(this.details.amenities));
      formData1.append("credits",JSON.stringify(this.details.credits));
      formData1.append("description",this.details.description);
      formData1.append('image',this.details.image);
      window
          .axios({
            method: "put",
            url: "project-resorts/"+id,
            data: formData1
          })
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.success = resp.data.message;
              this.getData();
              alert(resp.data.message);

            }
            setTimeout(() => (this.success = false), 3000);
          });
    },
  },
};
</script>

<style scoped>
</style>